import React from 'react'

const ElevenLogo = () => (
  <svg
    viewBox='0 0 436.1 162.2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='216'
    height='81'
  >
    <path fill="currentColor" d="M141.9,46.6C128.3,23.2,103.7,13.7,83,15c-26.3,1.7-62,20.7-62.3,61.6c-0.3,35.4,28.5,62.2,51.6,60.9c2.4-0.1,2.4,0.6,1,1.7c-5.2,4.2-8.3,3.7-10.6,4c-1.6,0.2-3.1,1.2-0.1,2.6c5.4,1.9,34.3,9.8,64.9-16.8C149.4,110,158,74,141.9,46.6z M71.5,105.3c0,2.6-2.1,4.7-4.7,4.7s-4.7-2.1-4.7-4.7V48c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7V105.3z M108.8,105.3c0,2.6-2.1,4.7-4.7,4.7s-4.7-2.1-4.7-4.7V48c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7V105.3z"/>
    <path fill="currentColor" d="M202.7,84.6c1,0,1.8-0.3,2.5-0.9c0.7-0.6,1.1-1.5,1.1-2.4V70.9c0-2.5-0.5-4.9-1.5-7.1c-1-2.1-2.3-4-3.9-5.6c-1.6-1.6-3.5-2.9-5.7-3.8c-2.2-0.9-4.5-1.3-7-1.3h-0.9c-2.5,0-4.9,0.4-7.1,1.3c-2.2,0.9-4.1,2.2-5.7,3.8c-1.6,1.6-2.9,3.5-3.8,5.7c-0.9,2.1-1.4,4.5-1.4,7v20.7c0,2.5,0.5,4.9,1.4,7.1c0.9,2.1,2.2,4,3.8,5.7c1.6,1.6,3.5,2.9,5.7,3.8c2.2,0.9,4.6,1.3,7.1,1.3h1.1c3.7,0,7.1-1,10-2.9c2.9-1.9,5-4.3,6.3-7.1c0.3-0.7,0.5-1.2,0.6-1.6c0.1-0.4,0.1-0.7,0.1-1c0-0.9-0.3-1.7-0.9-2.4c-0.6-0.7-1.5-1.1-2.5-1.1c-1.4,0-2.5,0.7-3.1,1.9c-0.9,2.1-2.3,3.9-4,5.3c-1.7,1.4-3.8,2-6.5,2h-1.1c-1.5,0-3-0.3-4.4-0.8c-1.3-0.5-2.5-1.3-3.4-2.3c-1-1-1.7-2.2-2.3-3.5c-0.6-1.3-0.9-2.8-0.9-4.3v-7H202.7z M176.3,77.9v-7c0-1.5,0.3-3,0.9-4.3c0.6-1.4,1.4-2.5,2.3-3.5c0.9-1,2.1-1.7,3.4-2.3c1.4-0.5,2.8-0.8,4.4-0.8h0.9c1.5,0,3,0.3,4.4,0.8c1.4,0.5,2.5,1.3,3.4,2.3c1,1,1.7,2.2,2.3,3.5c0.6,1.3,0.9,2.8,0.9,4.3v7H176.3z"/>
    <path fill="currentColor" d="M270.4,84.6c1,0,1.8-0.3,2.5-0.9c0.7-0.6,1.1-1.5,1.1-2.4V70.9c0-2.5-0.5-4.9-1.5-7.1c-1-2.1-2.3-4-3.9-5.6c-1.6-1.6-3.5-2.9-5.7-3.8c-2.2-0.9-4.5-1.3-7-1.3H255c-2.5,0-4.9,0.4-7.1,1.3c-2.2,0.9-4.1,2.2-5.7,3.8c-1.6,1.6-2.9,3.5-3.8,5.7c-0.9,2.2-1.4,4.5-1.4,7v20.7c0,2.5,0.5,4.9,1.4,7.1c0.9,2.1,2.2,4,3.8,5.7c1.6,1.6,3.6,2.9,5.7,3.8c2.2,0.9,4.6,1.3,7.1,1.3h1.1c3.7,0,7.1-1,10-2.9c2.9-1.9,5-4.3,6.3-7.1c0.3-0.7,0.5-1.2,0.6-1.6c0.1-0.4,0.1-0.7,0.1-1c0-0.9-0.3-1.7-0.9-2.4c-0.6-0.7-1.5-1.1-2.5-1.1c-1.4,0-2.5,0.7-3.1,1.9c-0.9,2.1-2.3,3.9-4,5.3c-1.7,1.4-3.8,2-6.5,2H255c-1.5,0-3-0.3-4.4-0.8c-1.3-0.5-2.5-1.3-3.4-2.3c-1-1-1.7-2.2-2.3-3.5c-0.6-1.3-0.9-2.8-0.9-4.3v-7H270.4z M244,77.9v-7c0-1.5,0.3-3,0.9-4.3c0.6-1.4,1.4-2.5,2.3-3.5c0.9-1,2.1-1.7,3.4-2.3c1.4-0.5,2.8-0.8,4.4-0.8h0.9c1.5,0,3,0.3,4.4,0.8c1.3,0.5,2.5,1.3,3.4,2.3c1,1,1.7,2.2,2.3,3.5c0.6,1.3,0.9,2.8,0.9,4.3v7H244z"/>
    <path fill="currentColor" d="M320.9,53.9c-0.7-0.5-1.5-0.8-2.3-0.8c-1.7,0-2.9,0.9-3.4,2.4l-13.2,41.3l-13.3-41.3c-0.4-1.5-1.6-2.4-3.3-2.4c-0.9,0-1.6,0.3-2.3,0.8c-0.8,0.6-1.2,1.5-1.2,2.5c0,0.7,0.1,1.5,0.4,2l15.7,48.3c0.4,1.1,1,1.8,1.7,2.2c0.6,0.3,1.3,0.5,1.9,0.5h0.5c0.6,0,1.3-0.2,2-0.5c0.8-0.4,1.4-1.2,1.8-2.3l15.7-48.2c0.3-0.8,0.4-1.4,0.4-2C322.1,55.4,321.7,54.5,320.9,53.9z"/>
    <path fill="currentColor" d="M364.3,84.6c1,0,1.8-0.3,2.5-0.9c0.7-0.6,1.1-1.5,1.1-2.4V70.9c0-2.5-0.5-4.9-1.5-7.1c-1-2.1-2.3-4-3.9-5.6c-1.6-1.6-3.5-2.9-5.7-3.8c-2.2-0.9-4.5-1.3-7-1.3h-0.9c-2.5,0-4.9,0.4-7.1,1.3c-2.2,0.9-4.1,2.2-5.7,3.8c-1.6,1.6-2.9,3.5-3.8,5.7c-0.9,2.1-1.4,4.5-1.4,7v20.7c0,2.5,0.5,4.9,1.4,7.1c0.9,2.1,2.2,4,3.8,5.7c1.6,1.6,3.6,2.9,5.7,3.8c2.2,0.9,4.6,1.3,7.1,1.3h1.1c3.7,0,7.1-1,10-2.9c2.9-1.9,5-4.3,6.3-7.1c0.3-0.7,0.5-1.2,0.7-1.7c0.1-0.4,0.1-0.7,0.1-1c0-0.9-0.3-1.7-0.9-2.4c-0.6-0.7-1.5-1.1-2.5-1.1c-1.4,0-2.5,0.7-3.1,1.9c-0.9,2.1-2.3,3.9-4,5.3c-1.7,1.4-3.8,2-6.5,2h-1.1c-1.5,0-3-0.3-4.4-0.8c-1.3-0.5-2.5-1.3-3.4-2.3c-1-1-1.7-2.2-2.3-3.5c-0.6-1.3-0.9-2.8-0.9-4.3v-7H364.3z M337.9,77.9v-7c0-1.5,0.3-3,0.9-4.3c0.6-1.3,1.4-2.5,2.3-3.5c0.9-1,2.1-1.7,3.4-2.3c1.4-0.5,2.8-0.8,4.4-0.8h0.9c1.5,0,3,0.3,4.4,0.8c1.3,0.5,2.5,1.3,3.4,2.3c1,1,1.7,2.2,2.3,3.5c0.6,1.3,0.9,2.8,0.9,4.3v7H337.9z"/>
    <path fill="currentColor" d="M222,109.9L222,109.9c-1.9,0-3.4-1.5-3.4-3.4V45.8c0-1.9,1.5-3.4,3.4-3.4l0,0c1.9,0,3.4,1.5,3.4,3.4v60.6C225.4,108.4,223.9,109.9,222,109.9z"/>
    <path fill="currentColor" d="M414.5,63.9L414.5,63.9c-1-2.1-2.3-4-3.9-5.6c-1.6-1.6-3.5-2.9-5.7-3.8c-2.2-0.9-4.5-1.3-7-1.3h-0.9c-2.5,0-4.9,0.4-7.1,1.3c-2.2,0.9-4.1,2.2-5.7,3.8c-1.6,1.6-2.9,3.5-3.8,5.7c-0.9,2.2-1.4,4.5-1.4,7l0,35.4c0.1,1.2,0.6,1.9,1.1,2.3c1.3,1.1,3.5,1.1,4.8,0c0.5-0.4,1-1.1,1.1-2.4l0-35.3c0-1.5,0.3-3,0.9-4.3c0.6-1.4,1.4-2.5,2.3-3.5c0.9-1,2.1-1.7,3.4-2.3c1.4-0.5,2.8-0.8,4.4-0.8h0.9c1.5,0,3,0.3,4.4,0.8c1.3,0.5,2.5,1.3,3.4,2.3c1,1,1.7,2.2,2.3,3.5c0.6,1.3,0.9,2.8,0.9,4.3v35.2c0,1.3,0.6,2.1,1.1,2.5c0.7,0.6,1.5,0.8,2.4,0.8c0.9,0,1.8-0.3,2.4-0.8c0.7-0.6,1.1-1.5,1.1-2.5V70.9C415.9,68.4,415.5,66,414.5,63.9z"/>
  </svg>

)

export default ElevenLogo
import React from 'react'

const ElevenIcon = () => (
  <svg
    viewBox='0 0 136 140'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='136'
    height='140'
  >
    <path fill="currentColor" d="M65.3134 0.120494C37.7099 1.89129 0.314897 21.8908 0.00240462 64.7023C-0.310088 101.785 29.8975 129.909 54.0636 128.555C56.5636 128.451 56.5636 129.18 55.1053 130.43C49.6887 134.805 46.4596 134.284 44.0639 134.596C42.3972 134.805 40.8348 135.846 43.9597 137.305C49.5846 139.284 79.8964 147.617 111.979 119.597C134.895 99.5973 143.957 61.8899 127.083 33.1405C112.812 8.76612 87.0837 -1.23364 65.3134 0.120494ZM52.2928 40.8487C52.2928 42.8278 51.8762 48.661 51.9804 50.7443C51.9804 51.4735 52.0845 52.3068 52.0845 53.1401C52.1887 54.0776 52.1887 55.015 52.1887 55.9525C52.1887 58.9733 52.0845 62.7232 51.9804 66.1606C51.8762 70.5355 52.0845 70.848 52.0845 77.202C52.0845 85.8476 51.8762 98.3474 51.8762 98.3474C51.772 100.118 50.8345 102.41 48.5429 102.514C44.8972 102.722 44.0639 100.014 44.168 97.9307C44.168 97.9307 44.3764 84.5977 44.3764 75.5354C44.3764 72.098 44.3764 69.0772 44.3764 65.2231C44.3764 59.9108 44.5847 54.4942 44.4805 45.5361C44.4805 43.8695 43.9597 39.0779 44.6889 35.7447C44.6889 35.7447 45.3138 32.7239 48.9596 32.7239C52.397 32.7239 52.2928 35.7447 52.2928 36.0571C52.397 36.1613 52.2928 36.7863 52.2928 40.8487ZM91.9794 58.0358C91.9794 61.0566 91.8752 70.9522 91.8752 77.202C91.8752 85.8476 91.6669 98.3474 91.6669 98.3474C91.4586 100.951 90.6253 102.41 88.3337 102.514C84.7921 102.722 84.3754 100.326 84.2713 97.9307C84.2713 97.9307 83.8546 98.139 83.8546 89.1809C83.8546 85.9518 84.0629 71.0563 84.1671 65.2231C84.2713 59.7024 84.2713 60.6399 84.2713 51.6818C84.2713 43.3486 84.5837 42.8278 84.5837 38.4529C84.5837 35.6405 85.3129 33.1405 87.917 33.2447C91.2503 33.2447 92.1877 35.0155 92.2919 36.578C92.2919 36.7863 92.2919 37.4113 92.2919 38.6613C92.2919 40.6404 92.2919 44.5986 92.3961 47.4111C92.3961 50.2235 91.9794 56.9942 91.9794 58.0358Z"/>
  </svg>

)

export default ElevenIcon
import React from 'react'

const strokeStyle = { 
  // vectorEffect: 'non-scaling-stroke' 
}

const ArrowTopLeft = () => (
  <svg 
  width="50" 
  height="50" 
  viewBox="0 0 50 50" 
  fill="none" 
  preserveAspectRatio='xMidYMid'
  xmlns="http://www.w3.org/2000/svg"
  >
    <path style={strokeStyle} d="M11.5456 38.7693L37.7626 12.5523" stroke="inherit" strokeWidth="2" strokeLinejoin="round"/>
    <path style={strokeStyle} d="M16.4639 11.2621L38.6117 11.3882L38.7378 33.536" stroke="inherit" strokeWidth="2" strokeLinejoin="round"/>
  </svg>

)

export default ArrowTopLeft

import React from 'react'

const BackslashIcon = () => (
  <svg
    viewBox='0 0 14 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='14'
    height='24'
  >
    <path fill="currentColor" d="M0 0L8.85503 24H13.9005L5.03704 0H0Z" />
  </svg>
)

export default BackslashIcon
import React from 'react'
import Nav from './nav'
import Footer from './footer'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({ children, companyInfo, siteInfo, onHideNav, onShowNav, showNav, noFooter, navMenu="sydney" }) => (
  <>
    <Nav companyInfo={companyInfo} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} navMenu={navMenu} />

    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>

      {(!noFooter)? <Footer companyInfo={companyInfo} siteInfo={siteInfo}  /> :null }
    </div>
  </>
)

export default Layout

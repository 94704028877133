import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import favicon from "../assets/favicon.png";

const detailsQuery = graphql`
  query SEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      url
    }
  }
`

function SEO ({ description, lang, meta, keywords = [], title, url, location }) {
  return (
    <StaticQuery
    query={detailsQuery}
    render={data => {
      if (!data.site) {
        return
      }
        const imageURL = 'https://www.elevenpr.com.au/og_card.png'
        let titleTemplate = !title ? data.site.title : `${data.site.title} - ${title}`
        if(location){ titleTemplate= `TBWA\\ ${location} - ${title}` }
        const metaDescription = description || data.site.description
        const metaKeywords = keywords.concat(data.site.keywords);

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={titleTemplate}
            link={[
              { rel: "icon", type: "image/png", href: `${favicon}` },
              { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
            ]}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: titleTemplate
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                property: 'og:url',
                content: url
              },
              {
                property: 'og:image',
                content: imageURL
              },
              {
                property: 'og:image:secure_url',
                content: imageURL
              },
              { property: 'og:image:type', content: 'image/png' },
              { property: 'og:image:width', content: '1080' },
              { property: 'og:image:height', content: '1080'},
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:title',
                content: titleTemplate
              },
              {
                name: 'twitter:description',
                content: metaDescription
              },
              {
                name: 'twitter:image',
                content: imageURL
              },
              {
                name: 'theme-color',
                content: '#FECC00'
              },
            ]
              .concat(
                metaKeywords && metaKeywords.length > 0
                  ? {
                    name: 'keywords',
                    content: metaKeywords.join(', ')
                  }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  siteURL: 'https://tbwa.com.au',
}

SEO.propTypes = {
  description: PropTypes.string,
  url: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO

import React from 'react'
import HamburgerIcon from './hamburger'
import SkullIcon from './skull'
import BackslashIcon from './backslash'
import ArrowTopLeft from './arrowTopLeft'
import ElevenIcon from './elevenIcon'
import ElevenLogo from './elevenLogo'

function Icon (props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon />
    case 'skull':
      return <SkullIcon />
    case 'backslash':
      return <BackslashIcon />
    case 'arrowTopLeft':
      return <ArrowTopLeft />
    case 'ElevenIcon':
      return <ElevenIcon />
    case 'ElevenLogo':
      return <ElevenLogo />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
